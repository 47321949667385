/*

Tooplate 2124 Vertex

https://www.tooplate.com/view/2124-vertex

*/

/* Loader 
https://ihatetomatoes.net/create-custom-preloading-screen/
*/
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;

    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    z-index: 1002;
}

    #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #e74c3c;

        -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #f9c922;

        -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
          animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    @-webkit-keyframes spin {
        0%   { 
            -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: rotate(0deg);  /* IE 9 */
            transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
        }
        100% {
            -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: rotate(360deg);  /* IE 9 */
            transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
        }
    }
    @keyframes spin {
        0%   { 
            -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: rotate(0deg);  /* IE 9 */
            transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
        }
        100% {
            -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: rotate(360deg);  /* IE 9 */
            transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
        }
    }

    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 51%;
        height: 100%;
        background: #222222;
        z-index: 1001;
        -webkit-transform: translateX(0);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(0);  /* IE 9 */
        transform: translateX(0);  /* Firefox 16+, IE 10+, Opera */
    }

    #loader-wrapper .loader-section.section-left {
        left: 0;
    }

    #loader-wrapper .loader-section.section-right {
        right: 0;
    }

    /* Loaded */
    .loaded #loader-wrapper .loader-section.section-left {
        -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateX(-100%);  /* IE 9 */
                transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);  
                transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    .loaded #loader-wrapper .loader-section.section-right {
        -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateX(100%);  /* IE 9 */
                transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */

-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);  
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
    
    .loaded #loader {
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;  
                transition: all 0.3s ease-out;
    }
    .loaded #loader-wrapper {
        visibility: hidden;

        -webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateY(-100%);  /* IE 9 */
                transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.3s 1s ease-out;  
                transition: all 0.3s 1s ease-out;
    }

/* General */

* {  box-sizing: border-box; }
html, body { 
    overflow-x: hidden;
    scroll-behavior: smooth;
 }
body { 
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    background-color: var(--brand_background_color);/* #333;*/
    color: var(--brand_dark_highlight);/* #999;*/
    margin: 0;
}
.hyperlink { 
    text-decoration: underline;
    color: var(--brand_dark_highlight);    
}
a { color: #fff; text-decoration: none; }
a:hover {  color:var(--brand_dark_cream_highlight)/* #9C9*/; }
button { cursor: pointer; }
a, button { transition: all 0.3s ease; }
ul {
    margin: 0;
    padding: 0;
}

.footer-container {
    display: flex;
    justify-content: center;
    background-color: var(--brand_dark_cream);
    margin: 10, 10, 10, 10;
}

.footer-content {
    padding: 10px 15px 10px 15px;
    align-items: center;
    justify-content: center;
}
.page-header {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 0;
    margin-top: 0px;
}
.page-header-small {
    margin-bottom: 5rem;
    text-align: center;
    
} 
.flex {
    display: flex;
}
h2 {
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 1.3em;
}

p {
    line-height: 1.8;
    margin-bottom: 50px;
}

.card-header {
    text-align: center;
    font-size: 20px;
    margin-top: 15px;
}
.card-content{
    text-align: center;

}
.card-price {
    text-align: center;
    font-size: 20px;
    bottom: 0px;
    margin-bottom: 0;
}

.card-content-container {
    flex-direction: column;
    height: 100%;
    position: relative;
    display: flex;
    
}
.small-icon {
    width: 1em;
    margin-right: 1em;
}

.copyright-icon {
    height: 24px;
    width: 24px;
}
.card-icon {
    width: 30px;
    position: absolute;
    right: 20px;
}
.card-icon-row{
    justify-content: flex-end;
    margin-bottom: 1em;
    max-height: 30px;
}

.small-image {
    border-radius: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

.align-center {
    align-items: center;
}

.contact-phone-mail {
    display: flex;
    align-items: center;
}

.content-center{
    display: flex;
    justify-content: center;
}

.photo-container{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 1em;
    overflow: hidden;
}
.contact-photo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.name {
    margin: 0;
    font-weight: bold;
    font-size: larger;
}

.zero-margin{
    margin: 0
}
span, footer {  line-height: 1.8; }

.tm-parallax{
    position: relative;
    min-height: 1000px;
    overflow: hidden;
    opacity: 0;
}

.position-fixed{
    position: fixed;
    top:0;
}

.tm-btn {
    display: inline-block;
    border: 1px solid #666;
    background-color: transparent;
    color: #999;
    padding: 14px 40px;
    font-size: 20px;
}

.tm-btn:hover {
    color: var(--brand_dark_highlight);/*#99CC99;*/
    border-color: var(--brand_dark_highlight);/*#99CC99;*/
}
.intro-text {
    text-align: center;
}
.tm-text-small { font-size: 0.9em; }
.tm-text-center { text-align: center; }
.tm-text-right { text-align: right; }
.tm-text-primary { color : var(--brand_dark_highlight);/*#99CC99;*/ }
.tm-text-secondary { color: #9999CC; }
.tm-text-link { color: #999; }
.tm-text-link:hover { color: #99CC99; }
.tm-relative { position: relative; }

.tm-hr {
    border-color: #555;
    max-width: 340px;
    width: 100%;    
}

.tm-mr {
    margin-right: 0;
    margin-left: auto;
}

.tm-ml {
    margin-left: 0;
    margin-right: auto;
}

.tm-my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}
.tm-mb-20 { margin-bottom: 20px; }
.tm-mb-50 { margin-bottom: 50px; }
.tm-mb-80 { margin-bottom: 80px; }
.tm-mb-130 { margin-bottom: 130px; }
.tm-mb-200 { margin-bottom: 200px; }

/* Layout */
.tm-section { 
    display: flex;
}

.tm-section-col {
    flex: 0 0 60%;
    width: 60%;
}
.tm-section-col img{
    max-width: 100%;
    display: hidden;
}
.fixed {
    position: fixed;
}


.tm-content {
    padding: 120px 60px 70px 100px;
    box-sizing: border-box;
}

.tm-content-small { padding: 190px 120px 70px; }
.tm-content-small-top { padding-top: 130px; }

.tm-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
    grid-template-rows: auto auto; /* Rows will adjust automatically based on content */
    grid-gap: 20px; /* Space between cards */
    max-width: 640px; /* Adjust width based on card size */
}

.tm-col {
    flex: 0 0 50%;
    padding: 25px;
    box-sizing: border-box;
}

.column-card {
    background-color: var(--brand_cream);
    border-radius: 35px;
    box-shadow: 10px 13px 14px 4px var(--brand_dark_cream_highlight);
    margin: 40px;
    display: flex;
    position: relative
}

/* Brand */
.tm-brand {
    position: fixed;
    bottom: 80px;
    right: 70px;
    width: 300px;
    height: 240px;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #333;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tm-brand-icon { margin-bottom: 25px; }
.tm-brand-text { font-size: 32px; }

/* Nav */
.navbar-toggler { display: none; }

.tm-nav {
    position: fixed;
    top: 0;
    left: -1px;
    width: 60%;
    z-index: 1000;  
}

.tm-nav ul {
    background-color: var(--brand_cream); /*#444;*/
    display: flex;
    justify-content: space-around;
}

.nav-item { list-style: none; }

.nav-link {
    color: var(--brand_dark_cream_highlight);/*#999;*/
    padding-top: 40px;
    padding-bottom: 40px;
    display: block;
    font-size: 20px;
}

.nav-link:hover,
.nav-link.current {
    color: var(--brand_dark_highlight);/*#99CC99;*/
}

/* Introduction */
.tm-media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 70px;
}

.tm-media img {
    width: 200px;
    height: 200px;
}

.tm-media-body {  padding-left: 30px; }

.tm-media-span {
    margin-bottom: 15px;
    display: block;
    text-align: right;
}

/* Gallery */

.tm-gallery-container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.tm-gallery-header {
    margin-left: 15px;
    margin-right: 15px;
}

.tm-gallery {
	position: relative;
	list-style: none;
	text-align: center;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.tm-gallery figure {
	position: relative;
	z-index: 50;
	/* float: left; */
	overflow: hidden;
	margin: 15px;
	min-width: 200px;
	max-width: 250px;
	max-height: 250px;
	width: 25%;
	text-align: center;
	cursor: pointer;
}

.tm-gallery figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.tm-gallery figure figcaption {
	padding: 1em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.tm-gallery figure figcaption::before,
.tm-gallery figure figcaption::after {
	pointer-events: none;
}

.tm-gallery figure figcaption,
.tm-gallery figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
    box-sizing: border-box;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.tm-gallery figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.tm-gallery figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
}

.tm-gallery figure h2 span { font-weight: 800; }

.tm-gallery figure h2,
.tm-gallery figure p {
	margin: 0;
}

.tm-gallery figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

.bottom-image {
    max-width: 100%;
}

/*-----------------*/
/***** Goliath *****/
/*-----------------*/

figure.effect-goliath {
	background: #99cc99;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-goliath img {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
	position: absolute;
	bottom: 0;
	left: 0;
    padding: 30px 10px;
    text-align: center;
    width: 100%;
}

figure.effect-goliath p {
	text-transform: none;
	font-size: 90%;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,50px,0);
	transform: translate3d(0,50px,0);
}

figure.effect-goliath:hover img {
	-webkit-transform: translate3d(0,-80px,0);
	transform: translate3d(0,-80px,0);
}

figure.effect-goliath:hover h2 {
	-webkit-transform: translate3d(0,-100px,0);
	transform: translate3d(0,-100px,0);
}

figure.effect-goliath:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.tm-gallery .tm-gallery-item.hide{ display: none; }

.tm-gallery .tm-gallery-item.show{
	display: block;
	animation: show .5s ease;
}

.tm-paging {
    display: flex;
    justify-content: center;
}

.tm-paging-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    color: #999;
    background-color: #222;
    margin: 7.5px;
}

.tm-paging-link:hover,
.tm-paging-link.active {
    background-color: #444444;
}

@keyframes show {
    0% {
    	opacity: 0;
    	transform: scale(0.9);
    }
    100% {
    	opacity: 1;
    	transform: scale(1);
    }
}

/* Contact */
.tm-contact-form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
.form-group { margin-bottom: 40px; }

.form-control {
    color: #999;
    font-size: 20px;
    font-family: 'Kumbh Sans', sans-serif;
    padding: 25px 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #666;
    background: transparent;
}

.form-control:focus {
    color: #999;
    background: transparent;
}

.mapouter {
    position: absolute;
    top: 240px;
    left: 0;
}

.gmap-canvas { width: 340px; }

.tm-copyright {
    margin-left: -50px;
    margin-bottom: -50px;
}

@media (max-width: 1200px) {

    .tm-content {
        padding-left: 20px;
        padding-right: 20px;

    }

    .tm-copyright { margin-left: 15px; }
}

@media (max-width: 992px) {
    .tm-brand { 
        position: static;
        margin-left: auto;
        margin-right: 0;
    }
    
    .tm-nav {
        width: 100%;
    }
    .tm-parallax {
        max-height: 600px;
    }

    .tm-content {
        padding-top: 90px;
    }

    .parallax-container {
        max-height: 600px;

    }
    .flex {
        flex-direction: column;
    }

    .tm-gallery figure { width: 33.33%; }

    figcaption h2 { font-size: 1.4rem; }

    .tm-section { flex-direction: column-reverse; height:100% }

    .tm-section-col {
        flex: 0 0 100%;
        width: 100%;
       
    }

    .tm-parallax { 
        min-height: 360px; 
    }

    #contact .tm-parallax {
        margin-bottom: 425px;
    }
    
    .mapouter {
        top: 360px;
        width: 100%;
    }

    .gmap-canvas { width: 100%; }

    .tm-copyright {
        margin-bottom: 0;
        padding: 30px;
    }
}

@media (max-width: 600px) {
    .tm-row { flex-direction: column; display: flex; align-items: center;}

    .tm-col {
        flex: 0 0 100%;
        width: 100%;
    }

    .tm-media img {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 560px) {
    .tm-sm-mt-30 { margin-top: 30px; }
}

@media (max-width: 516px) {
    .tm-gallery { display: block; }

    .tm-gallery figure {
        float: left;
        width: 45%;
        margin: 2.5%;
        min-width: 1px;
    }

    figure h2 { font-size: 1rem; }
}

@media (max-width: 420px) {
    .tm-media img {
        width: 100px;
        height: 100px;
    }
}



:root {
    --brand_background_color : #F5F5F5;
    --brand_cream : #F2EAD3;
    --brand_dark_cream : #DFD7BF;
    --brand_dark_highlight : #3F2305;
    --brand_dark_cream_highlight : #837e71;


    --original_color :  #444;
    --whitey : #fff;
    --grey : #444444;
    --hover_color : #99CC99;
}